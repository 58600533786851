import { create } from 'zustand';

type State = {
  title: React.ReactNode;
  description?: string;
  onConfirm?: () => void;
  icon?: React.ReactNode;
  confirmText?: string;
  isDimmed?: boolean;
};

type Actions = {
  showAlert: (params: State) => void;
  hideAlert: () => void;
  confirmAlert: () => void;
};

type Store = State & {
  actions: Actions;
};

const useAlertStore = create<Store>((set) => ({
  title: null,
  description: '',
  onConfirm: undefined,
  icon: null,
  confirmText: '확인', // 초기값은 undefined
  isDimmed: false,

  actions: {
    showAlert: (params: State) => {
      document.body.style.overflow = 'hidden';
      set({
        title: params.title,
        description: params.description,
        onConfirm: params.onConfirm,
        confirmText: params.confirmText || '확인', // confirmText가 없으면 기본값으로 '확인' 설정
        isDimmed: params.isDimmed,
        icon: params.icon,
      });
    },
    hideAlert: () => {
      document.body.style.overflow = '';
      set({
        title: null,
        description: '',
        onConfirm: undefined,
        confirmText: undefined,
        isDimmed: undefined,
        icon: null,
      });
    },
    confirmAlert: () => {
      set((state) => {
        if (state.onConfirm) {
          state.onConfirm();
        }
        return {
          title: null,
          description: '',
          onConfirm: undefined,
          confirmText: undefined,
          isDimmed: undefined,
          icon: null,
        };
      });
      document.body.style.overflow = '';
    },
  },
}));

export const useAlertActions = () => useAlertStore((state) => state.actions);
export const useAlertState = () => useAlertStore((state) => state);
