'use client';
import { useAlertActions, useAlertState } from '@/_global/_components/Alert/_stores/alert.store';
import { AlertParts } from '@/_global/_components/Alert/AlertParts';

export const Alert = () => {
  const { title, description, onConfirm, confirmText, icon, isDimmed = false } = useAlertState();
  const { hideAlert } = useAlertActions();

  if (!title) {
    return null;
  }

  return (
    <AlertParts.Wrapper dimmed={isDimmed}>
      <AlertParts.Body title={title} icon={icon} description={description} />
      <AlertParts.Footer
        confirmText={confirmText}
        onConfirm={() => {
          onConfirm?.();
          hideAlert();
        }}
      />
    </AlertParts.Wrapper>
  );
};
