import {
  EnrollmentTicketDetail,
  GetAllEnrollmentsResV1,
} from '@/(_pages)/(withGnb)/classroom/_domain/_types/classroom.type';
import { GetAllEnrollmentsRes } from '@/_global/_data/_models';
import { SystemVersion } from '@/_global/_types/global.type';
import Axios from '@/_global/_utils/axiosInstance.util';
import { RouteAxios } from '@/_global/_utils/routeApi.util';
import { queryOptions, useQuery } from '@tanstack/react-query';

export const classroomKeys = {
  all: ['classroom'],
  enrollments: (version: SystemVersion) => [...classroomKeys.all, 'enrollments', version],
  cards: () => [...classroomKeys.all, 'classroomEnrollments'],
};

export const classroomQuery = {
  // 구 시스템
  getCourseEnrollments: () =>
    queryOptions<GetAllEnrollmentsResV1>({
      queryKey: classroomKeys.enrollments(1),
      queryFn: () =>
        Axios('get', `${process.env.NEXT_PUBLIC_ONLINE_API}/v2/enrolleds/v3/classroom`),
    }),
  // 신 시스템
  getProductEnrollments: () =>
    queryOptions<GetAllEnrollmentsRes>({
      queryKey: classroomKeys.enrollments(2),
      queryFn: () => Axios('get', `/enrollment-record/enrollment/all`),
    }),
  getClassroomEnrollments: () =>
    queryOptions<EnrollmentTicketDetail[]>({
      queryKey: classroomKeys.cards(),
      queryFn: () => RouteAxios('get', `api/classroomEnrollments`),
    }),
};

export const useGetClassroomEnrollments = () => useQuery(classroomQuery.getClassroomEnrollments());
