import * as S from './spinner.css';
import { neutralDay } from '@/_global/_styles/design-system/foundation/color';

type SpinnerProps = {
  theme?: 'light' | 'dark';
};
export const Spinner = ({ theme }: SpinnerProps) => {
  return (
    <div className={S.wrapper}>
      <div className={S.spinnerContainer}>
        <SpinnerSvg theme={theme} />
      </div>
    </div>
  );
};

const THEME_COLORS = {
  light: neutralDay.gray60,
  dark: neutralDay.gray70,
};

const SpinnerSvg = ({ theme = 'light' }: SpinnerProps) => {
  const color = THEME_COLORS[theme];

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        d="M6.66707 19.9993C6.66707 12.6356 12.6366 6.66602 20.0004 6.66602C27.3642 6.66602 33.3337 12.6356 33.3337 19.9993C33.3337 27.3631 27.3642 33.3327 20.0004 33.3327"
        stroke="url(#paint0_linear_11087_99292)"
        strokeWidth="3.33344"
        strokeLinecap="round"
      />
      <path
        d="M20.0004 33.3327C27.3642 33.3327 33.3337 27.3631 33.3337 19.9993C33.3337 12.6356 27.3642 6.66602 20.0004 6.66602C12.6366 6.66602 6.66707 12.6356 6.66707 19.9993C6.66707 22.4279 7.31637 24.7049 8.45084 26.666"
        stroke="url(#paint1_linear_11087_99292)"
        strokeWidth="3.33344"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11087_99292"
          x1="26.6671"
          y1="33.3327"
          x2="5.83374"
          y2="25.8327"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="0.382331" stopColor={color} />
          <stop offset="0.818132" stopColor={color} stopOpacity="0.36" />
          <stop offset="1" stopColor={color} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11087_99292"
          x1="10.0004"
          y1="-7.50065"
          x2="21.6671"
          y2="28.3327"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="0.703125" stopColor={color} stopOpacity="0.208333" />
          <stop offset="0.848958" stopColor={color} stopOpacity="0" />
          <stop offset="1" stopColor={color} stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
