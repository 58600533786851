'use client';
import { LoginProcess } from '@teamsparta/auth-frontend';
import * as CPL from '@teamsparta/cross-platform-logger';
import React from 'react';
import { useLoginWhiteList, useShowLoginProcess } from '@/login/_domain/_business/login.hooks';
import { Loading } from '@/_global/_components/_higher_order/suspense/loading';
import { MySuspense } from '@/_global/_components/_higher_order/suspense/DefaultSuspense/DefaultSuspense.client';
import { getCurrentPath } from '@/_global/_utils/getCurrentPath.client.util';

export default function GetPageWithLogin({ children }: { children: React.ReactNode }) {
  const { isLoading, isLoggedIn, isShow, setIsShow } = useShowLoginProcess();

  const isLoginWhiteList = useLoginWhiteList();
  if (isLoginWhiteList) return <>{children}</>;

  return (
    <MySuspense isLoading={isLoading} fallback={<Loading.스피너 />}>
      {isLoggedIn ? (
        children
      ) : (
        <LoginProcess
          isShow={isShow}
          setIsShow={setIsShow}
          CPLogger={CPL}
          nextPage={getCurrentPath()}
          initialTextProps={{
            subTitle: '대한민국을 코딩의 땅으로',
          }}
        />
      )}
    </MySuspense>
  );
}
