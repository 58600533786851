'use client';

import { useGetClassroomEnrollments } from '@/(_pages)/(withGnb)/classroom/_domain/_data/classroom.query';
import { Flex } from '@/_global/_styles/design-system/components';
import { Text } from '@/_global/_styles/design-system/components/Text/Text';
import { neutralDay, scc } from '@/_global/_styles/design-system/foundation/color';

export default function ClassroomTitle() {
  const { data: enrollments } = useGetClassroomEnrollments();

  return (
    <Flex gap={4} align={'start'} fullWidth>
      <Text as="p" font="mTitle2" color={neutralDay.gray100}>
        내 강의
      </Text>
      <Text as="p" font="mTitle2" color={scc.red100}>
        {enrollments?.length}
      </Text>
    </Flex>
  );
}
