import { ReactNode, useEffect, useMemo } from 'react';
import * as S from './alert.css';
import ReactDOM from 'react-dom';
import { Button } from '@/_global/_styles/design-system/components/Button/Button';
import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { useAlertState } from '@/_global/_business/_hooks/useAlerteState';

type AlertBodyProps = {
  title: ReactNode;
  icon?: ReactNode;
  headerText?: string;
  description?: string;
};

const AlertBody = ({ title, icon, headerText = '', description = '' }: AlertBodyProps) => {
  const { isLms, theme } = useAlertState();

  return (
    <div className={S.AlertContent({ isLms })}>
      {icon && <div className={S.AlertIcon({ isLms })}>{icon}</div>}
      <div className={S.AlertTitleWrapper}>
        <div className={S.AlertTitleWrapperInner}>
          {headerText && <div className={S.AlertHeaderText}>{headerText}</div>}
          <h2 className={S.AlertTitle({ theme, isLms })}>{title}</h2>
        </div>
        {description && <p className={S.AlertSubTitle({ theme, isLms })}>{description}</p>}
      </div>
    </div>
  );
};

type AlertWrapperProps = {
  dimmed: boolean;
  children?: ReactNode;
};
const AlertWrapper = ({ children, dimmed, ...props }: AlertWrapperProps) => {
  const { theme, isLms } = useAlertState();
  const portalRoot = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    document.body.appendChild(portalRoot);
    return () => {
      document.body.removeChild(portalRoot);
    };
  }, [portalRoot]);

  return ReactDOM.createPortal(
    <div>
      <div className={S.AlertDimmed({ dimmed })} />
      <div {...props} className={S.AlertWrapper({ theme, isLms })}>
        {children}
      </div>
    </div>,
    portalRoot,
  );
};

const AlertFooter = ({
  confirmText = '확인',
  onConfirm,
}: {
  onConfirm: () => void;
  confirmText?: string;
}) => {
  const { theme } = useAlertState();
  const buttonWidth = confirmText.length < 6 ? '100px' : '100%';

  return (
    <Button
      size="lg"
      style={{
        width: buttonWidth,
        alignSelf: 'flex-end',
        whiteSpace: 'nowrap',
        ...(theme === 'dark' && {
          backgroundColor: neutralDay.white,
          color: neutralDay.gray100,
        }),
      }}
      colorScheme="secondary"
      onClick={onConfirm}
    >
      {confirmText}
    </Button>
  );
};

const AlertParts = {
  Body: AlertBody,
  Wrapper: AlertWrapper,
  Footer: AlertFooter,
};

export { AlertParts };
