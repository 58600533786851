'use client';

import QueryClientProviders from '@/_global/_providers/QueryClientProvider';
import { StackProvider } from '@teamsparta/stack-core';
import { PropsWithChildren } from 'react';

export const Providers = ({ children }: PropsWithChildren) => {
  return (
    <QueryClientProviders>
      <StackProvider>{children}</StackProvider>
    </QueryClientProviders>
  );
};
