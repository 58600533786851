import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios';

import { AxiosError } from 'axios';

export interface AxiosOptions {
  params?: Record<string, unknown>;
  data?: unknown;
  headers?: Record<string, string>;
  timeout?: number;
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
}

export interface ErrorResponse {
  apiMessage?: string;
  id: string;
  timestamp: string;
}

export type CustomError = AxiosError<ErrorResponse>;

export type HttpMethod = 'get' | 'post' | 'put' | 'patch' | 'delete';

const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const routeRequest = async <T>(
  method: HttpMethod,
  endpoint: string,
  options: AxiosOptions = {},
): Promise<T> => {
  const { params, data, headers = {}, timeout, ...rest } = options;

  const config: AxiosRequestConfig = {
    method,
    url: endpoint,
    params,
    headers,
    timeout,
    data,
    ...rest,
  };

  const response = await instance.request(config);
  return response.data;
};

export const RouteAxios = <T>(
  method: HttpMethod,
  endpoint: string,
  options?: AxiosOptions,
): Promise<T> => routeRequest<T>(method, endpoint, options);
