import "../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.15_@types+node@20.17.10_babel-plugin-macros@3.1.0_terser@_6rsr5w7p354r3sunqpwn6rxowe/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22app%2F_global%2F_styles%2Fdesign-system%2Ffont%2Ffont.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA8XWS26DMBAG4D2n8AUS%2BQUh5BK9QUWKCW7BJICUpFXvXvWRbjKLMT9StiNLn8aPf7x%2BVsHtVe%2Bl%2BEiEqPswrcbp2rpChH7oynZ3q56dPzRTITZS%2FtdG%2F%2B4KYeXx8l1qfXCr5m9Zmv0Wf9bVZefbayGeBje5UJVDtUs%2Bk%2FXNVvNtownbpnxbc%2B3sztaWsI3l2wawqT3XEXtuubZl2jnfTuf3rXLK3vLtbH7fsL0B%2Bs7A886BvlF7y7XTe5t6Y1rz7RLoG7X3gG0oW%2FLtF8BWhK0izrt64D13XNssb9fz55imssVEZMsBsNE51gA2Okv80rMk4u%2FwuvQ9j8iWt6XzPMJugb7RTO0emOcBmGNkniu%2B3S89SyLsI9A39T9XEe%2F7BPSN2gNgU7mmIrJlBN5YSp13RJ5PwAydZ38BPww%2BjocOAAA%3D%22%7D"
import "../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.15_@types+node@20.17.10_babel-plugin-macros@3.1.0_terser@_6rsr5w7p354r3sunqpwn6rxowe/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22app%2F_global%2F_components%2FAlert%2Falert.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA82V0W7aMBSG73kKS1MlKs2VE5I0uDdrpXK9N5hM7BAXx%2FYcU0KnvvsUJ6GYhgJqN4EQxM5vn5Pj7%2Fy5%2BRWgIM2DDQJ%2FRgBoVXHLlcQg5zWjdyMArNIYoOZKsNx2l2tObYFBgNBVMywYXxT2bTwn2XJh1EpSmCmhDAZmMSdj9B1035v4utG9QC4pqzGYTO9Gr6ObbTI4V9mqcimplRVcMgykksxTBe7%2BabF2loUHlllDZKWJYdJ6%2BsmhylBeaUE2GOSC1c1E8w8pNyxrlZkSq1LuFijnFmZKWhcDgLkylBloCOWrCoMg0XU7XcOqIFStMUC6BpGu3b13Txa4KqpnZnLRiAtOKZNeZSN3XguiMQhRs%2F3uk51U5uRAvb7N3MfT3h7STu4nj1HgadND2jAO0%2FDe006d9oyCE8EXEnLLyqqVw8oSY7e1SNtKexzvxCNfHS%2FZK%2F3cBciVtLCyG%2BHqbkoi7vrZdUdMgtB2ruIvDIOgy705LtiDFU7bSafLScnFBoOfhlkmKTEO1768aZBOU%2F%2FYMpdN%2BwwVEzkGlTXMZoWrUcEtg5UmGcNAGwbXhmhvee6W9%2FsHUZAEt55g4Qlm4SyZpZ6gOCbgH2aoDIVzw8gSgyVjGhIhvNXC2z6eJUny4AlKT%2FAYPT7MkCeQxwRqmJiDXOwsNS0L7mZhuFx2JqvmTyyzMOe2IU1awqVnO3OhsmWz04%2BSUU5AlRnGJCCSgnFJatjBHU2nur52MbYhIzfszD1uTbs3%2BO3QGWKuTNl5oyCWjWGMrr6D5ve6VTWRegozIrJxgBB9LgAEYaTrTtSlMmktqJnRhFIuF70rAfC6mx%2Ft8htukL0WuW1bxGuSt0B%2Bm6T99AeN4mXydE4m0UAmQTScSXhuJrrLxPlJ%2F6rwFFWn6A9%2BW4Nim1%2B%2FahgaLntoYoQ%2BhuZt89OpQQPIpA0sHiRR8h6SSfhZSJIhSIaPZhL9f0iSYUiST0Ey2Fp7kMThPiRxeCok7xGJL9xX2MX4yvJifOX3UWTs1%2FlKGh6B5gJ85SxI%2FqmvXCgkgy8fe4av%2FAWAZgNhdg4AAA%3D%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var AlertContent = _7a468({defaultClassName:'_1018f1yo',variantClassNames:{isLms:{true:'_1018f1yp',false:'_1018f1yq'}},defaultVariants:{},compoundVariants:[]});
export var AlertDimmed = _7a468({defaultClassName:'_1018f1y0',variantClassNames:{dimmed:{true:'_1018f1y1',false:'_1018f1y2'}},defaultVariants:{},compoundVariants:[]});
export var AlertHeaderText = '_1018f1yb';
export var AlertIcon = _7a468({defaultClassName:'_1018f1yr',variantClassNames:{isLms:{true:'_1018f1ys',false:'_1018f1yt'}},defaultVariants:{},compoundVariants:[]});
export var AlertSubTitle = _7a468({defaultClassName:'_1018f1yi',variantClassNames:{isLms:{true:'_1018f1yj',false:'_1018f1yk'},theme:{light:'_1018f1yl',dark:'_1018f1ym',darker:'_1018f1yn'}},defaultVariants:{},compoundVariants:[]});
export var AlertTitle = _7a468({defaultClassName:'_1018f1yc',variantClassNames:{isLms:{true:'_1018f1yd',false:'_1018f1ye'},theme:{light:'_1018f1yf',dark:'_1018f1yg',darker:'_1018f1yh'}},defaultVariants:{},compoundVariants:[]});
export var AlertTitleWrapper = '_1018f1y9';
export var AlertTitleWrapperInner = '_1018f1ya';
export var AlertWrapper = _7a468({defaultClassName:'_1018f1y3',variantClassNames:{isLms:{true:'_1018f1y4',false:'_1018f1y5'},theme:{light:'_1018f1y6',dark:'_1018f1y7',darker:'_1018f1y8'}},defaultVariants:{},compoundVariants:[]});